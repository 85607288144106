import { OrderByCondition, QueryCondition } from '../app/types/firebase-types';
import { orderBy, QueryConstraint, where } from 'firebase/firestore';
import { QUERY_CHUNK_SIZE } from '../constants/fetch-config';

/**
 * Generates query constraints based on provided conditions.
 * @param {QueryCondition[]} [queryConditions] - The query conditions.
 * @param {OrderByCondition[]} [orderByConditions] - The orderBy conditions.
 * @returns {QueryConstraint[]} An array of query constraints.
 */
export function generateQueryConstraints(queryConditions?: QueryCondition[] | null, orderByConditions?: OrderByCondition[] | null): QueryConstraint[] {
  const queryConstraints: QueryConstraint[] = [];
  // Add filter query conditions to the query constraints
  if (queryConditions) {
    queryConditions.forEach(condition => {
      queryConstraints.push(where(...condition));
    });
  }

  // Add orderBy conditions to the query constraints
  if (orderByConditions) {
    orderByConditions.forEach(condition => {
      queryConstraints.push(orderBy(...condition));
    });
  }

  return queryConstraints;
}

/**
 * Helper function to split an array into chunks
 * @param array 
 * @param chunkSize - default: chunk size constant
 * @returns 
 */
export function chunkArray<T>(array: T[], chunkSize: number = QUERY_CHUNK_SIZE): T[][] {
  return Array.from({ length: Math.ceil(array.length / chunkSize) }, (_, i) =>
    array.slice(i * chunkSize, i * chunkSize + chunkSize)
  );
}
