<div class="document-tile flexbox align-center justify-center">
  @if (isLoading$ | async) {
  <div class="spinner flexbox justify-center">
    <mat-spinner diameter="25"></mat-spinner>
  </div>
  } @else if (documentData?.id) {
  <div
    [ngClass]="{
      temporary: temporary,
      'default-border-color': temporary,
      'quaternary-background': temporary
    }"
    [matTooltip]="temporary ? 'Das Dokument wird beim Speichern verknüpft. ' + documentData?.name || '' : documentData?.name || ''"
    [attr.data-cy]="CY_DOCUMENTTILE_ELEMENT_OPENATTACHMENT"
    (keydown)="onAttachmentKeyDown($event, documentData!.id)"
    (click)="onOpenAttachment(documentData!.id)"
    class="attachment flexbox direction-column"
    tabindex="0"
    role="button"
  >
    <div class="tile-content-wrapper">
      <mat-icon class="attachment-icon"> description </mat-icon>
      <div class="attachment-name">
        {{ documentData?.name || '' }}
      </div>
    </div>
  </div>
  }
</div>
