import { Injectable } from '@angular/core';
import { DocumentData as EuleDocumentData, Permission } from '@eeule/eeule-shared';
import {
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  FirestoreError,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  QuerySnapshot,
  serverTimestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import { FullMetadata, getMetadata, getStorage, ref } from 'firebase/storage';
import { forkJoin, from, map, mergeMap, Observable, of, switchMap, throwError } from 'rxjs';
import { FirebaseService } from './firebase.service';
import { PermissionService } from './permission.service';
import { ProjectService } from './project.service';
import { StorageService } from './storage.service';

export type EuleDocumentWithMetadata = EuleDocumentData & { metadata?: FullMetadata };

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  constructor(
    private _firebaseService: FirebaseService,
    private _projectService: ProjectService,
    private _storageService: StorageService,
    private _permissionService: PermissionService
  ) {}

  /**
   * Retrieves a project document along with its metadata from Firebase storage and Firestore.
   *
   * @param {string} projectId - The ID of the project.
   * @param {string} documentId - The ID of the document.
   * @returns {Observable<EuleDocumentWithMetadata>} An observable emitting the eule document data with metadata.
   *
   * @memberOf StorageService
   */
  public getProjectDocumentWithMetadata(projectId: string, documentId: string): Observable<EuleDocumentWithMetadata> {
    const path: string = `projects/${projectId}/documents/${documentId}`;
    const docRef = doc(this._firebaseService.firestore, path);
    const storage = getStorage();
    const storageRef = ref(storage, path);
    const metaData$: Observable<FullMetadata> = from(getMetadata(storageRef));
    const documentData$: Observable<EuleDocumentData> = from(getDoc(docRef)).pipe(
      map((snap: DocumentSnapshot) => snap.data() as EuleDocumentData)
    );

    return metaData$.pipe(mergeMap(metaData => documentData$.pipe(map(documentData => ({ ...documentData, metadata: metaData })))));
  }

  /**
   * Retrieves all project documents from Firestore for a given project and user.
   * @param projectId - The ID of the project.
   * @param currentUserId - The ID of the current user.
   * @param userPermissions - The permissions of the current user.
   * @returns
   */
  public getLiveProjectUserDocumentsFromFirestore(projectId: string, currentUserId: string): Observable<EuleDocumentData[]> {
    return this._permissionService
      .getLiveUserProjectPermissions(currentUserId, projectId)
      .pipe(
        switchMap((userPermissions: Permission) =>
          this._getLiveProjectUserDocumentsFromFirestoreDependingOnPermissions(projectId, currentUserId, userPermissions.rights)
        )
      );
  }

  private _getLiveProjectUserDocumentsFromFirestoreDependingOnPermissions(
    projectId: string,
    currentUserId: string,
    userPermissionsRights: string[]
  ): Observable<EuleDocumentData[]> {
    const documentdColRef: CollectionReference<DocumentData, DocumentData> = collection(
      this._firebaseService.firestore,
      `projects/${projectId}/documents`
    );

    if (
      !userPermissionsRights.some(s => s === 'project_read_documents_own' || s === 'project_read_documents' || s === 'global_read_projects')
    ) {
      return throwError(() => new Error('User has no permission to read documents'));
    }

    const q =
      userPermissionsRights.includes('project_read_documents') || userPermissionsRights.includes('global_read_projects')
        ? query(documentdColRef)
        : query(documentdColRef, where('author', '==', currentUserId));

    return new Observable(observer => {
      return onSnapshot(
        q,
        (snapshot: QuerySnapshot<DocumentData, DocumentData>) =>
          observer.next(snapshot.docs.map(euleDocumentSnap => euleDocumentSnap.data() as EuleDocumentData)),
        (error: FirestoreError) => observer.error(error.message)
      );
    });
  }

  /**
   * Retrieves all project documents from Firestore for a given project.
   *
   * @param {string} projectId - The ID of the project.
   * @returns {Observable<EuleDocumentData[]>} An observable emitting an array of EuleDocumentData.
   *
   * @memberOf DocumentService
   */
  public getAllProjectDocumentsFromFirestore(projectId: string): Observable<EuleDocumentData[]> {
    const colRef = collection(this._firebaseService.firestore, `projects/${projectId}/documents`);
    return from(getDocs(colRef)).pipe(
      map(documentSnaps => documentSnaps.docs.map(documentsSnap => documentsSnap.data() as EuleDocumentData))
    );
  }

  /**
   * Retrieves all project documents from Firestore for a given project and includes their metadata from Firebase storage.
   *
   * @param {string} projectId - The ID of the project.
   * @returns {Observable<EuleDocumentWithMetadata[]>} An observable emitting an array of EuleDocumentWithMetadata.
   *
   * @memberOf DocumentService
   */
  public getAllProjectDocumentsWithMetaData(projectId: string): Observable<EuleDocumentWithMetadata[]> {
    return this.getAllProjectDocumentsFromFirestore(projectId).pipe(
      switchMap(documents => {
        if (!documents.length) return of([]);
        return forkJoin(
          documents.map(document => {
            const path: string = `projects/${projectId}/documents/${document.id}`;
            const storage = getStorage();
            const storageRef = ref(storage, path);
            return from(getMetadata(storageRef)).pipe(map(metaData => ({ ...document, metadata: metaData })));
          })
        );
      })
    );
  }

  /**
   * Deletes a document from Firestore and its associated storage.
   *
   * @param {string} documentId - The ID of the document to delete.
   * @returns {Observable<void>} An Observable that completes when the delete operation is done.
   *
   * @throws Will throw an error if no project is set or if no document ID is provided.
   *
   * @memberOf DocumentService
   */
  public deleteDoc(documentId: string) {
    if (!this._projectService.project$.value) {
      throw Error('No Project set');
    }
    if (!documentId) {
      throw Error('No Document ID');
    }
    const path: string = `projects/${this._projectService.project$.value.id}/documents/${documentId}`;
    return from(deleteDoc(doc(this._firebaseService.firestore, path))).pipe(
      switchMap(() => {
        return this._storageService.deleteDocument(path);
      })
    );
  }

  /**
   * Updates a project document in Firestore with new data and a timestamp.
   *
   * @param {string} projectId - The ID of the project.
   * @param {string} documentId - The ID of the document to update.
   * @param {Partial<EuleDocumentData>} data - The partial data to update in the document.
   * @returns {Observable<void>} An Observable that completes when the update operation is done.
   */
  public updateProjectDocument(projectId: string, documentId: string, data: Partial<EuleDocumentData>): Observable<void> {
    // Construct the path to the documents collection within the specified project
    const path: string = `projects/${projectId}/documents`;

    // Create a reference to the document
    const docRef: DocumentReference = doc(this._firebaseService.firestore, path, documentId);

    // Update the document with new data and timestamp
    return from(
      updateDoc(docRef, {
        ...data,
        updateTime: serverTimestamp(),
      })
    );
  }

  /**
   * Performs a bulk update of project documents in Firestore.
   *
   * This method takes an array of document update objects, each containing an ID and partial data,
   * and updates each document in the specified project. The updates are performed in parallel using `forkJoin`.
   *
   * @param {string} projectId - The ID of the project.
   * @param {Array<{ id: string; data: Partial<DocumentData> }>} documentsToUpdate - An array of objects containing document IDs and partial data to update.
   * @returns {Observable<void[]>} An Observable that completes when all update operations are done.
   */
  public performBulkUpdate(
    projectId: string,
    documentsToUpdate: Array<{
      id: string;
      data: Partial<DocumentData>;
    }>
  ): Observable<void[]> {
    const update$ = documentsToUpdate.map(o => this.updateProjectDocument(projectId, o.id, o.data));
    return forkJoin(update$);
  }
}
