import { AsyncPipe, NgClass } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatTooltip } from '@angular/material/tooltip';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, map, startWith, takeUntil } from 'rxjs';
import { handleBasicError } from '../../../../util/error.helper';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { SnackbarService } from '../../services/snackbar.service';
import { BaseComponent } from '../base/base.component';
import { SearchBarComponent } from '../search-bar/search-bar.component';

export const CY_CONNECTITEMSDIALOG_BUTTON_CLOSEISDIRTY = 'connect_items_dialog.button.close_isDirty';
export const CY_CONNECTITEMSDIALOG_BUTTON_CLOSE = 'connect_items_dialog.button.close';
export const CY_CONNECTITEMSDIALOG_BUTTON_OK = 'connect_items_dialog.button.ok';
export const CY_CONNECTITEMSDIALOG_BUTTON_ADD = 'connect_items_dialog.button.add';

export type ConnectItemsDialogConfig<T> = {
  items: T[]; // items to connect
  itemNameKey: string; // key of T to search for
  itemDescriptionKey?: string; // key of T to display as description
  isLoading?: boolean; // loading state, default is false
  dialogTitle?: string; // dialog title, default is 'Elemente zuweisen'
  listLabel?: string; // subtitle, default is 'Vorhandene Elemente'
  newElementButtonLabel?: string; // button label for creating a new element, default is 'Neu erstellen'
};

@Component({
  selector: 'eule-connect-items-dialog',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatIcon,
    MatIconButton,
    MatTooltip,
    MatDialogContent,
    AsyncPipe,
    SearchBarComponent,
    MatCheckbox,
    MatButton,
    MatDialogActions,
    MatProgressSpinner,
    NgClass,
  ],
  templateUrl: './connect-items-dialog.component.html',
  styleUrl: './connect-items-dialog.component.scss',
})
export class ConnectItemsDialogComponent<T> extends BaseComponent implements OnInit {
  CY_CONNECTITEMSDIALOG_BUTTON_CLOSEISDIRTY = CY_CONNECTITEMSDIALOG_BUTTON_CLOSEISDIRTY;
  CY_CONNECTITEMSDIALOG_BUTTON_CLOSE = CY_CONNECTITEMSDIALOG_BUTTON_CLOSE;
  CY_CONNECTITEMSDIALOG_BUTTON_OK = CY_CONNECTITEMSDIALOG_BUTTON_OK;
  CY_CONNECTITEMSDIALOG_BUTTON_ADD = CY_CONNECTITEMSDIALOG_BUTTON_ADD;

  /**
   * Event emitter for opening an item.
   */
  @Output() openItem: EventEmitter<string> = new EventEmitter<string>();

  /**
   * Event emitter for creating a new item.
   */
  @Output() createItem: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Key of the item name.
   */
  protected nameKey: keyof T = this.dialogData.itemNameKey as keyof T;

  /**
   * Key of the item description.
   */
  protected descriptionKey?: keyof T = this.dialogData.itemDescriptionKey as keyof T;

  /**
   * BehaviorSubject to hold the list of items.
   */
  public items$: BehaviorSubject<(T & { connected?: boolean; id: string })[]> = new BehaviorSubject<
    (T & {
      connected?: boolean;
      id: string;
    })[]
  >(this.dialogData.items as (T & { connected?: boolean; id: string })[]);

  /**
   * List of filtered items.
   */
  filteredItems: (T & { connected?: boolean; id: string })[] = [];

  /**
   * BehaviorSubject to hold the search term.
   */
  search$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  /**
   * Flag to indicate if the item list is dirty.
   */
  itemListIsDirty: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: ConnectItemsDialogConfig<T>,
    public dialogRef: MatDialogRef<ConnectItemsDialogComponent<T>>,
    public _snackBarService: SnackbarService,
    private _analyticsService: AnalyticsService
  ) {
    super();
  }

  /**
   * Lifecycle hook that is called after data-bound properties are initialized.
   * This method sets up the initial state of the component by combining the search term
   * and the list of items, and then filtering the items based on the search term.
   * It uses RxJS operators to debounce the search input, start with an initial value,
   * and ensure distinct values before performing the filtering.
   */
  ngOnInit(): void {
    combineLatest([
      // Debounce the search term input to avoid excessive filtering
      this.search$.pipe(
        debounceTime(200), // Wait for 200ms pause in events
        startWith(null), // Start with a null value
        distinctUntilChanged() // Only emit when the current value is different than the last
      ),
      // Map the items to filter out those with empty name keys
      this.items$.pipe(map(res => res.filter(o => (o[this.dialogData.itemNameKey as keyof T] as string)?.length))),
    ])
      .pipe(
        // Unsubscribe when the component is destroyed
        takeUntil(this.stop$)
      )
      .subscribe(([phrase, items]) => {
        // Filter the items based on the search term
        this.filteredItems = items.filter(attachment => {
          if (!phrase || phrase.length < 2) return true; // Show all items if the search term is empty or too short
          return (
            attachment[this.dialogData.itemNameKey as keyof T] &&
            attachment[this.dialogData.itemNameKey as keyof T]?.toString().indexOf(phrase) !== -1
          );
        });
      });
  }

  /**
   * Handler for item search.
   * @param searchTerm - The search term.
   */
  onItemSearch(searchTerm: string) {
    this.search$.next(searchTerm);
  }

  /**
   * Handler for opening an item.
   * @param id - The ID of the item to open.
   */
  onOpenItem(id: string) {
    this.openItem.emit(id);
  }

  /**
   * Handler for key down event on an item.
   * @param event - The keyboard event.
   * @param id - The ID of the item.
   */
  onItemKeyDown(event: KeyboardEvent, id: string) {
    if (event.key === 'Enter' || event.key === ' ') {
      this.openItem.emit(id);
      event.preventDefault();
    }
  }

  /**
   * Handler for creating a new item.
   */
  onNewItem() {
    this._analyticsService.sendEvent('click', {
      category: 'button',
      label: CY_CONNECTITEMSDIALOG_BUTTON_OK,
    });

    this.createItem.emit();
  }

  /**
   * Handler for connecting an item.
   * @param ev - The checkbox change event.
   * @param item - The item to connect.
   */
  onConnectItem(ev: MatCheckboxChange, item: T & { connected?: boolean; id: string }) {
    try {
      this.itemListIsDirty = true;

      const items: (T & { connected?: boolean; id: string })[] = this.items$.value.map(_item => ({
        ..._item,
        connected: _item.id === item.id ? ev.checked : _item.connected,
      }));

      this.items$.next(items);
    } catch (error) {
      this._snackBarService.showErrorMessage('Beim Verknüpfen des Anhangs ist ein Fehler aufgetreten');
      handleBasicError(error);
    }
  }

  /**
   * Getter for connected items.
   * @returns The list of connected items.
   */
  get connectedItems(): T[] {
    const connectedAttachments: T[] = this.items$.value;
    return connectedAttachments.filter(o => o['connected' as keyof T]);
  }

  /**
   * Get the name of an item.
   * @param item - The item.
   * @returns The name of the item.
   */
  getItemName(item: T): string {
    if (this.nameKey && typeof item[this.nameKey] === 'string') {
      return item[this.nameKey] as string;
    }
    return '';
  }

  /**
   * Get the description of an item.
   * @param item - The item.
   * @returns The description of the item.
   */
  getItemDescription(item: T): string {
    if (this.descriptionKey && typeof item[this.descriptionKey] === 'string') {
      return item[this.descriptionKey] as string;
    }
    return '';
  }

  /**
   * Set the list of items.
   * @param items - The list of items.
   */
  public setItems<TR>(items: TR[]) {
    this.items$.next(items as (T & { connected?: boolean; id: string })[]);
  }

  public closeDialog(value: boolean | T[] | null, label: string) {
    this._analyticsService.sendEvent('click', {
      category: 'button',
      label,
      value,
    });
    this.dialogRef.close(value);
  }
}
