import { Injectable } from '@angular/core';
import { collection, doc, getDoc, setDoc } from '@angular/fire/firestore';
import { Comment, TaskData } from '@eeule/eeule-shared';
import {
  DocumentData,
  DocumentReference,
  FirestoreError,
  QueryDocumentSnapshot,
  QuerySnapshot,
  deleteDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc
} from 'firebase/firestore';
import { BehaviorSubject, Observable, forkJoin, from, map } from 'rxjs';
import { getEnumKeyByValue } from '../../../util/enum.helper';
import { DisciplineEnum } from '../../enums/Discipline.enum';
import { PriorityEnum } from '../../enums/Priority.enum';
import { TaskStatusEnum } from '../../enums/TaskStatus.enum';
import { TaskTypeEnum } from '../../enums/TaskType.enum';
import { FirebaseService } from './firebase.service';

export type TaskDiscipline = keyof typeof DisciplineEnum;

export type TaskType = keyof typeof TaskTypeEnum;

export type TaskPriority = keyof typeof PriorityEnum;

export type TaskStatus = keyof typeof TaskStatusEnum;

@Injectable({
  providedIn: 'root',
})
export class TaskService {
  public nextTaskNumber$: BehaviorSubject<number>;

  constructor(private _firebaseService: FirebaseService) {
    this.nextTaskNumber$ = new BehaviorSubject<number>(123);
  }

  public setTask(projectId: string, taskData: TaskData): Observable<void> {
    const docRef = doc(this._firebaseService.firestore, `projects/${projectId}/tasks/${taskData.id}`);
    return from(setDoc(docRef, { ...taskData, createTime: serverTimestamp(), updateTime: serverTimestamp() }));
  }

  public updateTask(projectId: string, taskData: TaskData): Observable<void> {
    const docRef = doc(this._firebaseService.firestore, `projects/${projectId}/tasks/${taskData.id}`);
    return from(updateDoc(docRef, { ...taskData, updateTime: serverTimestamp() }));
  }

  public getAllProjectTasks(projectId: string): Observable<TaskData[]> {
    const colRef = collection(this._firebaseService.firestore, `projects/${projectId}/tasks`);
    return from(getDocs(colRef)).pipe(map(tasksSnaps => tasksSnaps.docs.map(tasksSnap => tasksSnap.data() as TaskData)));
  }

  public getLiveAllProjectTasks(projectId: string): Observable<TaskData[]> {
    const q = query(collection(this._firebaseService.firestore, `projects/${projectId}/tasks`));
    return new Observable(observer => {
      return onSnapshot(
        q,
        (snapshot: QuerySnapshot<DocumentData, DocumentData>) =>
          observer.next(snapshot.docs.map((taskSnap: QueryDocumentSnapshot<DocumentData, DocumentData>) => taskSnap.data() as TaskData)),
        (error: FirestoreError) => observer.error(error.message),
      );
    });
  }

  public getProjectTask(projectId: string, taskId: string): Observable<TaskData> {
    const docRef = doc(this._firebaseService.firestore, `projects/${projectId}/tasks/${taskId}`);
    return from(getDoc(docRef)).pipe(map(taskSnap => ({ ...taskSnap.data(), id: taskSnap.id } as TaskData)));
  }

  /**
   * FIXME: Contains possible memory leak because of a open subscription. Needs to be checked.
   *
   * @param {string} projectId
   * @param {string} taskId
   * @returns {Observable<Comment[]>}
   *
   * @memberOf TaskService
   */
  public getLiveAllTaskComments(projectId: string, taskId: string): Observable<Comment[]> {
    const q = query(collection(this._firebaseService.firestore, `projects/${projectId}/tasks/${taskId}/comments`), orderBy('createdDate'));
    return new Observable(observer => {
      return onSnapshot(
        q,
        (snapshot: QuerySnapshot<DocumentData, DocumentData>) => observer.next(snapshot.docs.map(tasksSnap => tasksSnap.data() as Comment)),
        (error: FirestoreError) => observer.error(error.message),
      );
    });
  }

  public updateProjectTask(projectId: string, taskId: string, data: Partial<TaskData>) {
    const path: string = `projects/${projectId}/tasks`;
    const docRef: DocumentReference = doc(this._firebaseService.firestore, path, taskId);
    // Update the document with new data and timestamp
    return from(
      updateDoc(docRef, {
        ...data,
        updateTime: serverTimestamp(),
      }),
    );
  }

  public deleteProjectTaskComment(projectId: string, taskId: string, Commentd: string): Observable<void> {
    if (!projectId || !taskId || !Commentd) {
      throw new Error(`not all params set ${projectId}, ${taskId}, ${Commentd}}`);
    }
    const docRef = doc(this._firebaseService.firestore, `projects/${projectId}/tasks/${taskId}/comments/${Commentd}`);
    return from(deleteDoc(docRef));
  }

  public setProjectTaskComment(projectId: string, taskId: string, comment: Comment): Observable<void> {
    const docRef = doc(this._firebaseService.firestore, `projects/${projectId}/tasks/${taskId}/comments/${comment.id}`);
    return from(setDoc(docRef, comment));
  }

  public updateProjectTaskComment(projectId: string, taskId: string, comment: Partial<Comment>): Observable<void> {
    const docRef = doc(this._firebaseService.firestore, `projects/${projectId}/tasks/${taskId}/comments/${comment.id}`);
    return from(updateDoc(docRef, comment));
  }

  /**
   * Performs a bulk update of project tasks in Firestore.
   *
   * This method takes an array of task update objects, each containing an ID and partial data,
   * and updates each task in the specified project. The updates are performed in parallel using `forkJoin`.
   *
   * @param {string} projectId - The ID of the project.
   * @param {Array<{ id: string; data: Partial<TaskData> }>} documentsToUpdate - An array of objects containing task IDs and partial data to update.
   * @returns {Observable<void[]>} An Observable that completes when all update operations are done.
   */
  public performBulkUpdate(projectId: string, documentsToUpdate: Array<{
    id: string;
    data: Partial<TaskData>
  }>): Observable<void[]> {
    const update$ = documentsToUpdate.map(o =>
      this.updateProjectTask(projectId, o.id, o.data),
    );
    return forkJoin(update$);
  }

  // /**
  //  * Attention: Adding a Document to a Task that not exists yet (adding document while on a New-task-interface that has not yet been saved), would cause an error.
  //  * In this case the task is created only with its ID and the (updated) array of documents. This is by current state necessary since in this workflow we also create
  //  * the codument in the storage and only reference it here. On the one hand this makes it smoother for the user since he does not have to save and reopen the task
  //  * before adding documents. On the other hand this could lead to tasks that stay incomplete (if the user starts task creating, adds doc and cancels the task creation).
  //  * Alternative would be to cancel and undo the whole workflow if the user cancels the new-task dialog. this would mean we need to clean up also the document from
  //  * all references and the storage.
  //  *
  //  * @param {string} projectId
  //  * @param {string} taskId
  //  * @param {string} documentId
  //  * @returns {Observable<Observable<void>>}
  //  *
  //  * @memberOf TaskService
  //  */
  // public addDocumentToTask(projectId: string, taskId: string, documentId: string): Observable<Observable<void>> {
  //   const docRef = doc(this._firebaseService.firestore, `projects/${projectId}/tasks/${taskId}`);
  //   return this.getProjectTask(projectId, taskId).pipe(
  //     map((task: TaskData) => {
  //       if (task) {
  //         const _array = task.attachmentsIds || [];
  //         return from(updateDoc(docRef, { attachmentsIds: [..._array, documentId] }));
  //       } else {
  //         return from(setDoc(docRef, { attachmentsIds: [documentId] }));
  //       }
  //     })
  //   );
  // }

  /** helper */

  /**
   * Generates a map of tasks grouped by their discipline.
   *
   * This function takes an array of tasks and groups them by their discipline.
   * If a task does not have a discipline, it defaults to 'OPEN'.
   *
   * @param {TaskData[]} tasks - An array of task data objects.
   * @returns {Map<TaskDiscipline, TaskData[]>} A map where the keys are disciplines and the values are arrays of tasks.
   */
  generateDisciplineTasksMap(tasks: TaskData[]): Map<TaskDiscipline, TaskData[]> {
    const taskMap = new Map<TaskDiscipline, TaskData[]>();
    for (const task of tasks) {
      const taskDiscipline: TaskDiscipline = Object.keys(DisciplineEnum).includes(task.discipline as string)
        ? (task.discipline || 'OPEN') as TaskDiscipline
        : getEnumKeyByValue(DisciplineEnum, task.discipline as string) || 'OPEN';
      if (taskMap.has(taskDiscipline as TaskDiscipline)) {
        taskMap.get(taskDiscipline as TaskDiscipline)?.push(task);
        continue;
      }
      taskMap.set(taskDiscipline as TaskDiscipline, [task]);
    }
    return taskMap;
  }

  /**
   * Generates a map of tasks grouped by their type.
   *
   * This function takes an array of tasks and groups them by their type.
   * If a task does not have a type, it defaults to 'TASK'.
   *
   * @param tasks
   */
  generateTypeTasksMap(tasks: TaskData[]): Map<TaskType, TaskData[]> {
    const taskMap = new Map<TaskType, TaskData[]>();
    for (const task of tasks) {
      const taskType: TaskType = Object.keys(TaskTypeEnum).includes(task.type)
        ? (task.type || 'TASK') as unknown as TaskType
        : getEnumKeyByValue(TaskTypeEnum, task.type) || 'TASK';
      if (taskMap.has(taskType as TaskType)) {
        taskMap.get(taskType as TaskType)?.push(task);
        continue;
      }
      taskMap.set(taskType as TaskType, [task]);
    }
    return taskMap;
  }

  /**
   * Generates a map of tasks grouped by their status.
   *
   * This function takes an array of tasks and groups them by their status.
   * If a task does not have a status, it defaults to 'INACTIVE'.
   *
   * @param {TaskData[]} tasks - An array of task data objects.
   * @returns {Map<TaskStatus, TaskData[]>} A map where the keys are statuses and the values are arrays of tasks.
   */
  generateStatusTasksMap(tasks: TaskData[]): Map<TaskStatus, TaskData[]> {
    const taskMap = new Map<TaskStatus, TaskData[]>();
    for (const task of tasks) {
      const taskStatus: TaskStatus = Object.keys(TaskStatusEnum).includes(task.status as string)
        ? (task.status || 'INACTIVE') as unknown as TaskStatus
        : getEnumKeyByValue(TaskStatusEnum, task.status as string) || 'INACTIVE';
      if (taskMap.has(taskStatus as TaskStatus)) {
        taskMap.get(taskStatus as TaskStatus)?.push(task);
        continue;
      }
      taskMap.set(taskStatus as TaskStatus, [task]);
    }
    return taskMap;
  }

  /**
   * Generates a map of tasks grouped by their priority.
   *
   * This function takes an array of tasks and groups them by their priority.
   * If a task does not have a priority, it defaults to 'MINOR'.
   *
   * @param tasks
   */
  generatePriorityTasksMap(tasks: TaskData[]): Map<TaskPriority, TaskData[]> {
    const taskMap = new Map<TaskPriority, TaskData[]>();
    for (const task of tasks) {
      const taskPriority: TaskPriority = Object.keys(PriorityEnum).includes(task.priority as string)
        ? (task.priority || 'MINOR') as unknown as TaskPriority
        : getEnumKeyByValue(PriorityEnum, task.priority as string) || 'MINOR';
      if (taskMap.has(taskPriority as TaskPriority)) {
        taskMap.get(taskPriority as TaskPriority)?.push(task);
        continue;
      }
      taskMap.set(taskPriority as TaskPriority, [task]);
    }
    return taskMap;
  }
}
