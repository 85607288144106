import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DocumentData, Project } from '@eeule/eeule-shared';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { handleBasicError } from '../../../../util/error.helper';
import { BaseComponent } from '../../../core/components/base/base.component';
import { AnalyticsService } from '../../../core/services/analytics/analytics.service';
import { ProjectService } from '../../../core/services/project.service';
import { StorageService } from '../../../core/services/storage.service';

export const CY_DOCUMENTTILE_ELEMENT_OPENATTACHMENT = 'document_tile.element.open_attachment';

@Component({
  selector: 'eule-document-tile',
  standalone: true,
  imports: [CommonModule, MatCardModule, MatDialogModule, MatProgressSpinnerModule, MatIconModule, MatTooltipModule],
  templateUrl: './document-tile.component.html',
  styleUrl: './document-tile.component.scss',
})
export class DocumentTileComponent extends BaseComponent implements OnChanges {
  CY_DOCUMENTTILE_ELEMENT_OPENATTACHMENT = CY_DOCUMENTTILE_ELEMENT_OPENATTACHMENT;

  @Input({ required: true }) documentId!: string;
  @Input() temporary?: boolean;
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public documentData: DocumentData | null = null;

  public constructor(
    private _projectService: ProjectService,
    private _storageService: StorageService,
    private _analyticsService: AnalyticsService
  ) {
    super();
  }

  ngOnChanges() {
    if (this.documentId) {
      this.isLoading$.next(true);
      this._storageService
        .getProjectDocumentData(this._projectService.project$.value!.id, this.documentId)
        .subscribe((_documentData: DocumentData) => {
          this.isLoading$.next(false);
          this.documentData = _documentData;
        });
    }
  }

  async onAttachmentKeyDown(event: KeyboardEvent, id: string) {
    if (event.key === 'Enter' || event.key === ' ') {
      await this.onOpenAttachment(id);
      event.preventDefault();
    }
  }

  async onOpenAttachment(id: string) {
    this._analyticsService.sendEvent('click', {
      category: 'element',
      label: CY_DOCUMENTTILE_ELEMENT_OPENATTACHMENT,
    });

    const project: Project | null = this._projectService.project$.value;
    if (!project) {
      return handleBasicError('Error while retrieving current project');
    }
    const downloadUrl: string = await lastValueFrom(this._storageService.getProjectDocumentDownloadUrl(project.id, id));
    window.open(downloadUrl, '_blank');
  }
}
