import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'eule-custom-snackbar',
  standalone: true,
  imports: [MatProgressBarModule, MatButtonModule, MatIconModule],
  templateUrl: './custom-snackbar.component.html',
  styleUrl: './custom-snackbar.component.scss',
})
export class CustomSnackbarComponent {
  progress = 0;
  private elapsed = 0;
  private interval!: NodeJS.Timeout;
  private remainingTime: number;
  private lastStartTime!: number;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: { message: string; duration: number; actionText: string },
    private snackBarRef: MatSnackBarRef<CustomSnackbarComponent>
  ) {
    this.remainingTime = data.duration;
    this.startTimer();
  }

  private startTimer() {
    this.lastStartTime = Date.now();
    this.interval = setInterval(() => {
      const now = Date.now();
      const deltaTime = now - this.lastStartTime;
      this.lastStartTime = now;
      this.elapsed += deltaTime;
      this.progress = (this.elapsed / this.data.duration) * 100;

      if (this.elapsed >= this.data.duration) {
        clearInterval(this.interval);
        this.snackBarRef.dismiss();
      }
    }, 100);
  }

  pauseTimer() {
    clearInterval(this.interval);
    this.remainingTime -= this.elapsed;
  }

  resumeTimer() {
    this.elapsed = 0;
    this.startTimer();
  }

  onActionClick() {
    this.snackBarRef.dismissWithAction();
  }
}
