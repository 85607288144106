<div class="snackbar-container" (mouseenter)="pauseTimer()" (mouseleave)="resumeTimer()">
  <div>
    <span>{{ data.message }}</span>
    <button mat-raised-button color="primary" (click)="onActionClick()" class="report-button">
      <mat-icon>report</mat-icon>{{ data.actionText || 'Bug' }}
    </button>
  </div>

  <mat-progress-bar mode="determinate" class="progress-bar" [value]="progress"></mat-progress-bar>
</div>
