import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { StripeError } from '@stripe/stripe-js';
import { FirebaseError } from 'firebase/app';
import { CustomSnackbarComponent } from '../components/custom-snackbar/custom-snackbar.component';

export type Severity = 'success' | 'warning' | 'error' | 'default' | undefined;

export interface ErrorLog {
  message?: string;
  data?: ErrorLogData;
}

export interface ErrorLogData {
  [key: string]: unknown;
  originalError?: unknown;
}

@Injectable({
  providedIn: 'root',
})
/**
 * @author Christian Sanker
 * Service to manage snack bar notifications.
 */
export class SnackbarService {
  constructor(private _snackBar: MatSnackBar, private _router: Router) {}

  /**
   * Shows a message in the snack bar.
   * @param {string} message - The message to display.
   * @param {Severity} [severity] - The severity of the message (optional).
   * @param {number} [duration] - The duration of the message display (optional).
   */
  showMessage(message: string, severity?: Severity, duration?: number): void {
    const config: MatSnackBarConfig = new MatSnackBarConfig();
    if (severity) config.panelClass = severity;
    config.duration = duration || 2000;
    config.horizontalPosition = 'center';
    this.openSnackbar(message, undefined, config);
  }

  /**
   * Shows an error message in the snack bar.
   * @param {string} message - The error message to display.
   * @param {number} [duration] - The duration of the error message display (optional).
   */
  showErrorMessage(message?: string, duration: number = 2000, errorLog?: ErrorLog) {
    const config: MatSnackBarConfig = new MatSnackBarConfig();
    config.panelClass = 'error';
    config.duration = duration;
    config.horizontalPosition = 'center';

    let originalErrorType = '';
    try {
      const serialized: string = serializeError(errorLog?.data?.originalError);
      if (errorLog?.data?.originalError && errorLog.data.originalError instanceof FirebaseError) {
        originalErrorType = `(Firebase-Fehler) ${errorLog?.data.originalError.code} ${errorLog?.data.originalError.message}: `;
      }
      if (errorLog?.data?.originalError && serialized.toLowerCase().includes('firebase')) {
        originalErrorType = `(Firebase-Fehler): `;
      }
      if (errorLog?.data?.originalError && errorLog.data.originalError instanceof HttpErrorResponse) {
        originalErrorType = `(HTTP-Fehler) ${errorLog?.data.originalError.status} ${errorLog?.data.originalError.message}: `;
      }
      if (
        errorLog?.data?.originalError &&
        typeof (errorLog?.data?.originalError as StripeError)?.type === 'string' &&
        (errorLog.data.originalError as StripeError)?.type.toLowerCase().includes('stripe')
      ) {
        originalErrorType = `(Stripe-Fehler): `;
      }
    } catch (error) {
      console.error('Fehler beim Verarbeiten des Fehlerlogs:', error);
    }
    if (errorLog) {
      this._openCustomSnackBar(originalErrorType + (message || 'Es ist ein Fehler aufgetreten.'), config, errorLog);
    } else {
      this.openSnackbar(message || 'Es ist ein Fehler aufgetreten.', undefined, config);
    }
    errorLog && console.error(errorLog);
  }

  /**
   * Opens Mat Snackbar
   * @param message
   * @param action
   * @param config
   * @private
   */
  private openSnackbar(message: string, action?: string, config?: MatSnackBarConfig) {
    // TODO integrate action if needed
    if (action) console.warn(action, 'TODO: integrate action');
    this._snackBar.open(message, undefined, config);
  }

  private _openCustomSnackBar(message: string, config: MatSnackBarConfig<{ actionText: string }>, errorLog: ErrorLog) {
    this._snackBar
      .openFromComponent(CustomSnackbarComponent, {
        ...config,
        data: { message, duration: config.duration, actionText: config.data?.actionText },
      })
      .onAction()
      .subscribe(() => this._router.navigate(['/intern/feedback'], { state: { errorLog: errorLog } }));
  }
}

export function serializeError(error: unknown): string {
  if (!error) return '{}';

  return JSON.stringify(
    {
      /* eslint-disable @typescript-eslint/no-explicit-any */
      name: (error as any).name || 'UnknownError',
      message: (error as any)?.message || 'No message',
      stack: (error as any)?.stack || 'No stack trace',
      type: (error as any)?.type || 'No type',
      code: (error as any)?.code || 'No code',
      /* eslint-enable @typescript-eslint/no-explicit-any */
    },
    null,
    2
  );
}
