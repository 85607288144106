import { Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { Permission, Scope } from '@eeule/eeule-shared';
import { map, Observable } from 'rxjs';
import { CollectionQueryResponse, DocumentQueryResponse } from '../../types/firebase-types';
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  /**
   * A writable signal that holds the current user's rights.
   */
  private currentUserRights: WritableSignal<string[] | null> = signal<string[] | null>(null);

  /**
   * A readonly signal that exposes the current user's rights.
   */
  readonly currentUserRightsSig: Signal<string[] | null> = this.currentUserRights.asReadonly();

  /**
   * A writable signal that holds the current user's project-specific rights.
   */
  private currentUserProjectRights: WritableSignal<string[] | null> = signal<string[] | null>(null);

  /**
   * A readonly signal that exposes the current user's project-specific rights.
   */
  readonly currentUserProjectRightsSig: Signal<string[] | null> = this.currentUserProjectRights.asReadonly();

  /**
   * Constructs the PermissionService.
   *
   * @param {FirebaseService} _firebaseService - The Firebase service used for data operations.
   */
  constructor(private _firebaseService: FirebaseService) {}

  /**
   * Retrieves the permissions for a user.
   *
   * @param {string} authUserId - The ID of the authenticated user.
   * @returns {Observable<DocumentQueryResponse<Permission>>} An observable emitting the user's permissions.
   */
  public getUserPermissions(authUserId: string): Observable<DocumentQueryResponse<Permission>> {
    return this._firebaseService.getDocumentData<Permission>('permissions', authUserId, true);
  }

  /**
   * Returns an array observable of the projectIds the user has permissions on.
   *
   * @param {string} authUserId
   * @returns {Observable<string[]>}
   *
   * @memberOf PermissionService
   */
  public getUserPermissionedProjectIds(authUserId: string): Observable<string[]> {
    return this._firebaseService.getCollectionData<Permission>(`permissions/${authUserId}/projects`, null, null, true).pipe(
      map((response: CollectionQueryResponse<Permission>) => {
        return response.docs?.map(doc => doc.id) || [];
      })
    );
  }

  /**
   * Listens to live updates of a user's permissions.
   *
   * @param {string} authUserId - The ID of the authenticated user.
   * @returns {Observable<Permission>} An observable emitting live updates of the user's permissions.
   */
  public getLiveUserPermissions(authUserId: string): Observable<Permission> {
    return this._firebaseService.listenToDocumentChangesOnSnapshot<Permission>('permissions', authUserId);
  }

  /**
   * Retrieves the project-specific permissions for a user.
   *
   * @param {string} authUserId - The ID of the authenticated user.
   * @param {string} projectId - The ID of the project.
   * @returns {Observable<DocumentQueryResponse<Permission>>} An observable emitting the user's project-specific permissions.
   */
  public getUserProjectPermissions(authUserId: string, projectId: string): Observable<DocumentQueryResponse<Permission>> {
    return this._firebaseService.getDocumentData<Permission>(`permissions/${authUserId}/projects`, projectId, true);
  }

  /**
   * Listens to live updates of a user's project-specific permissions.
   *
   * @param {string} authUserId - The ID of the authenticated user.
   * @param {string} projectId - The ID of the project.
   * @returns {Observable<Permission>} An observable emitting live updates of the user's project-specific permissions.
   */
  public getLiveUserProjectPermissions(authUserId: string, projectId: string): Observable<Permission> {
    return this._firebaseService.listenToDocumentChangesOnSnapshot<Permission>(`permissions/${authUserId}/projects`, projectId);
  }

  /**
   * Sets the current user's rights.
   *
   * @param {string[]} rights - An array of rights to set for the current user.
   */
  public setCurrentUserRights(rights: string[]): void {
    this.currentUserRights.set(rights);
  }

  /**
   * Sets the current user's project-specific rights.
   *
   * @param {string[]} rights - An array of project-specific rights to set for the current user.
   */
  public setCurrentUserProjectRights(rights: string[]): void {
    this.currentUserProjectRights.set(rights);
  }

  /**
   * Checks if the current user has the specified rights.
   *
   * @param {string[]} rights - An array of rights to check.
   * @param {Scope} scope - The scope of the rights to check.
   * @param {boolean} needAllRightsForPermission - Whether all rights are needed for the permission.
   * @returns {boolean} True if the user has all the specified rights, false otherwise.
   */
  public hasRightsForScope(rights: string[], scope: Scope = 'project', needAllRightsForPermission: boolean = false): boolean {
    if (needAllRightsForPermission) {
      return scope === 'project'
        ? rights.every(right => this.currentUserProjectRightsSig()?.includes(right))
        : rights.every(right => this.currentUserRightsSig()?.includes(right));
    }
    return scope === 'project'
      ? rights.some(right => this.currentUserProjectRightsSig()?.includes(right))
      : rights.every(right => this.currentUserRightsSig()?.includes(right));
  }

  /**
   * Checks if the current user has all the specified rights.
   *
   * @param {...string[]} rights - An array of rights to check.
   * @returns {boolean} True if the user has all the specified rights, false otherwise.
   */
  public hasRights(...rights: string[]): boolean {
    return rights.every(right => this.currentUserProjectRightsSig()?.includes(right) || this.currentUserRightsSig()?.includes(right));
  }

  /**
   * Checks if the current user has any of the specified rights.
   *
   * @param {...string[]} rights - An array of rights to check.
   * @returns {boolean} True if the user has any of the specified rights, false otherwise.
   */
  public hasAnyRights(...rights: string[]): boolean {
    return rights.some(right => this.currentUserProjectRightsSig()?.includes(right) || this.currentUserRightsSig()?.includes(right));
  }
}
