<div mat-dialog-title class="dialog-title flexbox align-center smaller-spacing">
  <div class="title">
    {{ dialogData.dialogTitle || 'Elemente zuweisen' }}
  </div>
  <div class="flex-grow-1 flexbox justify-end">
    <button
      mat-icon-button
      class="close-button smaller-button"
      [matTooltip]="'Dialog schließen'"
      (click)="closeDialog(null, CY_CONNECTITEMSDIALOG_BUTTON_CLOSEISDIRTY)"
      [attr.data-cy]="CY_CONNECTITEMSDIALOG_BUTTON_CLOSEISDIRTY"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<mat-dialog-content class="dialog-content flexbox direction-column smaller-gap">
  <eule-search-bar
    [search]="(search$ | async) || ''"
    (searchChange)="onItemSearch($event)"
    class="w-100-percent item-list-search"
    [fullWidth]="true"
  />
  <div class="item-list flexbox direction-column xs-gap">
    <div class="item-list-label strong">
      {{ dialogData.listLabel || 'Vorhandene Elemente' }}
    </div>
    <div
      class="items secondary-background default-border flexbox direction-column small-gap"
      [ngClass]="{ 'no-items': !filteredItems || filteredItems.length === 0 }"
    >
      @if(filteredItems) { @for(item of filteredItems; track item) {
      <div class="item flexbox small-gap cursor-pointer align-center" [matTooltip]="getItemDescription(item)">
        <mat-checkbox
          [checked]="item.connected"
          (change)="onConnectItem($event, item)"
          [attr.data-cy]="'checkbox-connect-items-dialog-item' + $index"
        />
        <div
          class="item-name cursor-pointer"
          (keydown)="onItemKeyDown($event, item.id)"
          tabindex="0"
          role="button"
          (click)="onOpenItem(item.id)"
        >
          {{ getItemName(item) }}
        </div>
      </div>
      } }
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="dialog-actions">
  @if (dialogData.isLoading) {
  <div class="flexbox align-center small-gap loading-indicator">
    <mat-spinner diameter="25" />
    <div class="secondary-text spinner-text">Daten werden verarbeitet</div>
  </div>
  } @else {
  <button
    mat-flat-button
    color="accent"
    class="flexbox xs-gap align-center"
    (click)="onNewItem()"
    type="button"
    [attr.data-cy]="CY_CONNECTITEMSDIALOG_BUTTON_OK"
  >
    <mat-icon>add</mat-icon>
    <div class="button-text">
      {{ dialogData.newElementButtonLabel || 'Neu Erstellen' }}
    </div>
  </button>
  @if (itemListIsDirty) {
  <button
    mat-flat-button
    type="button"
    color="primary"
    (click)="closeDialog(connectedItems, CY_CONNECTITEMSDIALOG_BUTTON_OK)"
    [attr.data-cy]="CY_CONNECTITEMSDIALOG_BUTTON_OK"
  >
    Ok
  </button>
  } @else {
  <button
    mat-stroked-button
    type="button"
    (click)="closeDialog(null, CY_CONNECTITEMSDIALOG_BUTTON_CLOSE)"
    [attr.data-cy]="CY_CONNECTITEMSDIALOG_BUTTON_CLOSE"
  >
    Schließen
  </button>
  } }
</mat-dialog-actions>
