import { Injectable } from '@angular/core';
import { doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { fromEvent, merge, Subscription } from 'rxjs';
import { auditTime } from 'rxjs/operators';
import { FirebaseService } from '../firebase.service';

@Injectable({
  providedIn: 'root',
})
export class ActivityTrackerService {
  private userId: string | null = null;
  public activity: Subscription | null = null;

  constructor(private _firebaseService: FirebaseService) {}

  init(userId: string) {
    this.userId = userId;

    // Überwache Benutzerinteraktionen
    const activityEvents$ = merge(fromEvent(document, 'mousemove'), fromEvent(document, 'keydown'));

    // Update Firestore bei Aktivität
    this.cleanUp();
    this.activity = activityEvents$.pipe(auditTime(60000)).subscribe(() => this.updateLastActive());
  }

  cleanUp() {
    if (this.activity) {
      this.activity.unsubscribe();
    }
  }

  private async updateLastActive() {
    if (!this.userId) return;

    const userDoc = doc(this._firebaseService.firestore, `users/${this.userId}`);
    await updateDoc(userDoc, { lastActive: serverTimestamp() });
  }
}
