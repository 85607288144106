import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Project } from '@eeule/eeule-shared';
import { Comment, DocumentData, ProjectUser, TaskData } from '@eeule/eeule-shared';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  EMPTY,
  from,
  iif,
  lastValueFrom,
  Observable,
  of,
  pipe,
  Subscription,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { handleBasicError } from '../../../../util/error.helper';
import { BaseComponent } from '../../../core/components/base/base.component';
import { ConfirmDialogComponent } from '../../../core/components/confirm-dialog/confirm-dialog.component';
import { DocumentService } from '../../../core/services/document.service';
import { ProjectService } from '../../../core/services/project.service';
import { SnackbarService } from '../../../core/services/snackbar.service';
import { TaskService } from '../../../core/services/task.service';
import { ProjectUserDisplay, UserService } from '../../../core/services/user.service';
import { DisciplineEnum } from '../../../enums/Discipline.enum';
import { PriorityEnum } from '../../../enums/Priority.enum';
import { TaskStatusEnum } from '../../../enums/TaskStatus.enum';
import { TaskTypeEnum } from '../../../enums/TaskType.enum';
import { DocumentTileComponent } from '../document-tile/document-tile.component';
import { TaskIndicatorListComponent } from '../task-indicator-list/task-indicator-list.component';
import {
  UploadDocumentDialogComponent,
  UploadDocumentDialogConfig,
} from '../upload-document-dialog/upload-document-dialog.component';
import {
  ConnectItemsDialogComponent,
  ConnectItemsDialogConfig,
} from '../../../core/components/connect-items-dialog/connect-items-dialog.component';
import { StorageService } from '../../../core/services/storage.service';
import { DocumentDataX } from '../../../types/common-types';
import {
  CustomQuillEditorToolbarSetComponent,
} from '../../../core/components/custom-quill-editor-toolbar-set/custom-quill-editor-toolbar-set.component';
import { QuillEditorComponent } from 'ngx-quill';
import { PermissionService } from '../../../core/services/permission.service';
import { Auth } from '@angular/fire/auth';
import { FirebaseDocumentData } from '../../../types/firebase-types';
import { CustomTooltipDirective } from '../../../core/directives/custom-tooltip.directive';

export interface IndicatorTaskDialogComponentConfig {
  id?: string | null;
}

@Component({
  selector: 'eule-indicator-task-dialog',
  standalone: true,
  imports: [
    DocumentTileComponent,
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    MatTooltipModule,
    TaskIndicatorListComponent,
    CustomQuillEditorToolbarSetComponent,
    QuillEditorComponent,
    CustomTooltipDirective,
  ],
  providers: [],
  templateUrl: './indicator-task-dialog.component.html',
  styleUrl: './indicator-task-dialog.component.scss',
})
export class IndicatorTaskDialogComponent extends BaseComponent implements OnInit {
  public statusArray = TaskStatusEnum;
  // public indicatorArray = IndicatorStatusEnum; // FIXME: load all concrete indicators (without children but indicatorData) and provide them as a multi select
  public typeArray = TaskTypeEnum;
  public disciplineEnum = DisciplineEnum;
  // public criteriaArray = CriteriaEnum;
  public priorityArray = PriorityEnum;
  public responsibleArray: ProjectUserDisplay[] = [];
  public creatorArray: ProjectUserDisplay[] = [];

  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isUpdating$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public taskInitialized$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  // public refetchAttachments$: Subject<void> = new Subject<void>();
  public currentTask: TaskData | null = null;
  public taskForm: FormGroup | null = null;
  public commentsForm: FormGroup = this._formBuilder.group({});
  public taskAttachmentsDirty: boolean = false;
  public projectAttachments: Partial<DocumentDataX>[] = [];
  public taskAttachments: Partial<DocumentDataX>[] = [];
  public taskAttachmentsFromServer: Partial<DocumentDataX>[] = [];
  public leistungsPhasenList: Array<number> = Array.from({ length: 9 }, (_, i) => i + 1);
  private connectAttachmentsDialogRef: MatDialogRef<ConnectItemsDialogComponent<Partial<DocumentDataX>>> | null
    = null;

  /**
   * Creates an instance of IndicatorTaskDialogComponent.
   * @param {IndicatorTaskDialogComponentConfig} data data.id is used as indicator for an already existing task. Data without ID indicates a new task.
   * @param _permissionService
   * @param {MatDialogRef<IndicatorTaskDialogComponent>} dialogRef
   * @param {MatDialog} _dialog
   * @param {TaskService} _taskService
   * @param {DocumentService} _documentService
   * @param {FormBuilder} _formBuilder
   * @param {SnackbarService} _snackBarService
   * @param {ProjectService} _projectService
   * @param {UserService} _userService
   * @param {StorageService} _storageService
   * @param _auth
   * @memberOf IndicatorTaskDialogComponent
   */
  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: IndicatorTaskDialogComponentConfig,
    public _permissionService: PermissionService,
    public dialogRef: MatDialogRef<IndicatorTaskDialogComponent>,
    public _taskService: TaskService,
    public _projectService: ProjectService,
    private _dialog: MatDialog,
    private _documentService: DocumentService,
    private _formBuilder: FormBuilder,
    private _snackBarService: SnackbarService,
    private _userService: UserService,
    private _storageService: StorageService,
    private _auth: Auth
  ) {
    super();
  }

  ngOnInit(): void {
    this._initializeTaskDialog();
  }

  get formDirty(): boolean {
    if (!this.taskForm) return false;
    return (
      !!this.taskForm.get('status')?.dirty ||
      !!this.taskForm.get('title')?.dirty ||
      !!this.taskForm.get('type')?.dirty ||
      !!this.taskForm.get('date')?.dirty ||
      !!this.taskForm.get('discipline')?.dirty ||
      !!this.taskForm.get('leistungsPhasen')?.dirty ||
      !!this.taskForm.get('responsibleId')?.dirty ||
      !!this.taskForm.get('priority')?.dirty ||
      !!this.taskForm.get('description')?.dirty ||
      this.taskAttachmentsDirty
    );
  }

  /**
   * Initializes the task dialog.
   */
  private _initializeTaskDialog() {
    this._loadUsers();

    if (!this.data.id) {
      this.taskForm = this._createTaskForm(); // if no id was provided, create task form and skip loading task data. a present id indicates that the dialog is in edit mode
      return;
    }

    this.loadTaskDataAndDependencies() // if id was provided, load task data and dependencies, task form will be created in the subscription
  }

  /**
   * Closes the dialog and optionally saves the task.
   *
   * @param {boolean} save - Indicates whether to save the task before closing the dialog.
   * @throws {Error} If the task ID is not found.
   */
  public closeDialog(save: boolean): void {
    if (!save) {
      return this.dialogRef.close();
    }
    this.isUpdating$.next(true);
    if (!this.taskForm?.get('id')?.value) {
      this._snackBarService.showErrorMessage('Beim Speichern der Aufgabe ist ein Fehler aufgetreten');
      throw new Error('an error occurred while saving a project task. TaskId was not found');
    }
    const _date = this.taskForm.value.date.valueOf();

    if (!this.taskForm) {
      this._snackBarService.showErrorMessage('Beim Speichern der Aufgabe ist ein Fehler aufgetreten');
      throw new Error('Task form was not initialized');
    }
    // FIXME: IMPORTANT! This should be a server side firebase function, not client side (evaluation of number of total documents)
    iif(
      // if taskID exists
      () => !!this.taskForm?.get('taskNumber')?.value,
      // return taskId
      of(this.taskForm.get('taskNumber')!.value),
      // else return new incremented taskId depending on all currently existing tasks
      from(this._taskService.getAllProjectTasks(this._projectService.project$.value!.id)).pipe(
        switchMap((allTasks: TaskData[]) => {
          // FIXME: IMPORTANT! This means also that in the database it is NOT ALLOWED to delete task-documents (only deactivate)
          let oldNumber: number = (allTasks.length += 1);
          return of(oldNumber++);
        }),
      ),
    )
      .pipe(
        switchMap((taskNumber: number) => {
          const taskMutation$: Observable<void> = !this.data.id
            ? from(
              this._taskService.setTask(this._projectService.project$.value!.id, {
                ...this.taskForm!.getRawValue(),
                date: _date,
                taskNumber: taskNumber,
              }),
            )
            : from(
              this._taskService.updateTask(this._projectService.project$.value!.id, {
                ...this.taskForm!.getRawValue(),
                indicatorReferences: this.currentTask?.indicatorReferences || [],
                date: _date,
                taskNumber: taskNumber,
              }),
            );
          return taskMutation$.pipe(
            switchMap(() => {
              if (!this.taskAttachmentsFromServer?.length && !this.taskAttachments.length) return of(null);
              const project: Project | null = this._projectService.project$.value;
              if (!project?.id) return of(null);
              const taskAttachmentReferencesToRemove = this.taskAttachmentsFromServer.filter(o => {
                return !this.taskAttachments.some(co => co.id === o.id);
              });
              return this._documentService.performBulkUpdate(
                project.id,
                [
                  ...this.taskAttachments.map(o => ({
                    id: o.id!,
                    data: {
                      tasksIds: Array.from(new Set([...(o.tasksIds || []), this.taskForm!.get('id')!.value])),
                    },
                  })),
                  ...taskAttachmentReferencesToRemove.map(o => ({
                    id: o.id!,
                    data: {
                      tasksIds: Array.from(new Set(o.tasksIds?.filter(id => id !== this.taskForm!.get('id')!.value) || [])),
                    },
                  })),
                ]);
            }),
          );
        }),
        catchError(error => {
          this._snackBarService.showErrorMessage('Beim Speichern der Aufgabe ist ein Fehler aufgetreten');
          this.isUpdating$.next(false);
          throw new Error(error);
        }),
        takeUntil(this.stop$),
      )
      .subscribe(() => {
        this.dialogRef.close(this.taskForm!.get('id')!.value);
      });
    this.isUpdating$.next(false);
  }

  /**
   * Retrieves the user name based on the user ID.
   *
   * @param {string | null} [userId] - The ID of the user to retrieve the name for.
   * @returns {string} The user's name or email, or an empty string if not found.
   */
  public getUserName(userId?: string | null): string {
    if (!userId) {
      console.error('User with id "', userId, '" was not found.');
      return '';
    }
    const user: ProjectUserDisplay | undefined = this.creatorArray.find(o => o.id === userId);
    if (!user) {
      return '';
    }
    return user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.email || '';
  }

  /**
   * Adds a new comment to the task.
   */
  public addComment() {
    this._taskService.setProjectTaskComment(this._projectService.project$.value!.id, this.taskForm!.value.id, {
      id: crypto.randomUUID(),
      content: this.taskForm!.get('newCommentControl')!.value,
      creatorId: this._projectService.projectUser$.value!.id,
      createdDate: new Date().getTime(),
      lastUpdatedDate: new Date().getTime(),
    });
    this.taskForm!.get('newCommentControl')?.reset();
  }

  /**
   * Deletes a comment from the task.
   *
   * @param {AbstractControl | null} control - The form control of the comment to delete.
   * @throws {Error} If the control name cannot be found.
   */
  public deleteComment(control: AbstractControl | null) {
    if (!this._getControlName(control)) {
      this._snackBarService.showErrorMessage('Beim Löschen des Kommentars ist ein Fehler aufgetreten.');
      throw new Error('Cannot find control name');
    }
    this._dialog
      .open(ConfirmDialogComponent, {
        width: '360px',
        data: { dynamicContent: 'Kommentar löschen' },
      })
      .afterClosed()
      .pipe(takeUntil(this.stop$))
      .subscribe(takeAction => {
        if (takeAction) {
          this._taskService.deleteProjectTaskComment(this._projectService.project$.value!.id, this.taskForm!.value.id, this._getControlName(control)!);
          pipe(
            catchError(err => {
              console.error(err);
              this._snackBarService.showErrorMessage('Beim Löschen des Kommentars ist ein Fehler aufgetreten.');
              return EMPTY;
            }),
          );
        }
      });
  }

  /**
   * Should only be visible if the current user is the creator of this comment
   *
   * @param {(AbstractControl<any, any> | null)} formGroupControl
   *
   * @memberOf IndicatorTaskDialogComponent
   */
  public toggleEditComment(formGroupControl: AbstractControl | FormGroup | null) {
    if (!formGroupControl) {
      throw new Error('no control');
    }
    formGroupControl.enable();
  }

  /**
   * Saves the comment by updating the project task comment with the provided form group control.
   *
   * @param {AbstractControl | FormGroup | null} formGroupControl - The form group control containing the comment data.
   * @throws {Error} If the form group control is null or the control name cannot be found.
   */
  public saveComment(formGroupControl: AbstractControl | FormGroup | null) {
    if (!formGroupControl) {
      throw new Error('no control');
    }
    if (!this._getControlName(formGroupControl)) {
      throw new Error('Cannot find control name');
    }
    this._taskService.updateProjectTaskComment(this._projectService.project$.value!.id, this.taskForm!.value.id, {
      id: this._getControlName(formGroupControl)!,
      content: formGroupControl.get('content')!.value,
      lastUpdatedDate: new Date().getTime(),
    });
    formGroupControl.disable();
  }

  /**
   * Creates a form group for the provided comments and adds them to the comments form.
   *
   * @private
   * @param {Comment[]} _comments - The array of comments to create form groups for.
   */
  private _createCommentsForm(_comments: Comment[]): void {
    this.commentsForm = this._formBuilder.group({});
    _comments.forEach((comment: Comment) => {
      const singleCommentGroup = new FormGroup({
        id: new FormControl(comment.id),
        content: new FormControl({ value: comment.content, disabled: true }),
        createdDate: new FormControl(comment.createdDate),
        lastUpdatedDate: new FormControl(comment.lastUpdatedDate || null),
        creatorId: new FormControl(comment.creatorId),
        title: new FormControl(comment.title || null),
      });
      singleCommentGroup.disable();
      this.commentsForm.addControl(comment.id, singleCommentGroup);
    });
  }

  /**
   * Retrieves the form group for the specified comment control key.
   *
   * @param {string} commentControlKey - The key of the comment control to retrieve.
   * @returns {FormGroup} The form group for the specified comment control key.
   */
  public getCommentControlGroup(commentControlKey: string): FormGroup {
    return this.commentsForm.get(commentControlKey) as FormGroup;
  }

  /**
   * Retrieves the creator ID for the specified comment control key.
   *
   * @param {string} commentControlKey - The key of the comment control to retrieve the creator ID for.
   * @returns {string} The creator ID for the specified comment control key.
   */
  public getCommentCreatorId(commentControlKey: string): string {
    const commentGroup = this.commentsForm.get(commentControlKey) as FormGroup;
    return commentGroup.get('creatorId')!.value;
  }

  /**
   * Retrieves the last updated date for the specified comment control key.
   *
   * @param {string} commentControlKey - The key of the comment control to retrieve the last updated date for.
   * @returns {Date} The last updated date for the specified comment control key.
   */
  public getCommentLastUpdatedDate(commentControlKey: string): Date {
    const commentGroup = this.commentsForm.get(commentControlKey) as FormGroup;
    return new Date(commentGroup.get('lastUpdatedDate')!.value);
  }

  /**
   * Opens the dialog to add attachments to the task.
   * This method initializes the dialog with the current project attachments and sets up
   * subscriptions to handle the dialog's events for closing, opening an item, and creating a new item.
   */
  public onOpenAddAttachmentDialog() {
    // Open the ConnectItemsDialogComponent with the provided configuration
    this.connectAttachmentsDialogRef = this._dialog.open<
      ConnectItemsDialogComponent<Partial<DocumentDataX>>,
      ConnectItemsDialogConfig<Partial<DocumentDataX>>,
      Partial<DocumentDataX>[] | null
    >(ConnectItemsDialogComponent, {
      data: {
        items: this.projectAttachments,
        itemNameKey: 'name',
        itemDescriptionKey: 'description',
        dialogTitle: 'Anhänge verknüpfen',
        listLabel: 'Vorhandene Projektdokumente',
        newElementButtonLabel: 'Hochladen',
      },
      width: '400px',
      minHeight: '400px',
    });

    // Subscribe to the afterClosed event to handle the dialog close action
    const afterClosedSub: Subscription = this.connectAttachmentsDialogRef.afterClosed()
      .subscribe((connectedAttachments) => {
        if (!connectedAttachments) {
          afterClosedSub.unsubscribe();
          openItemSub.unsubscribe();
          createItemSub.unsubscribe();
          return;
        }

        // Filter the task attachments to include only those that are connected
        const filteredTaskAttachments: Partial<DocumentDataX>[] = this.taskAttachments.filter(ta => {
          return connectedAttachments.some((ca: Partial<DocumentDataX>) => ca.id === ta.id);
        });

        // Add new attachments to the filtered list
        for (const attachment of connectedAttachments) {
          if (this.taskAttachments.some(fa => fa.id === attachment.id)) continue;
          filteredTaskAttachments.push({
            ...attachment,
            temporary: !this.taskAttachmentsFromServer.map(o => o.id).includes(attachment.id),
          });
        }

        this.taskAttachmentsDirty = true;
        this.taskAttachments = Array.from(new Set([...filteredTaskAttachments]));
        afterClosedSub.unsubscribe();
        openItemSub.unsubscribe();
        createItemSub.unsubscribe();
      });

    // Subscribe to the openItem event to handle opening an attachment
    const openItemSub: Subscription = this.connectAttachmentsDialogRef.componentInstance.openItem
      .subscribe((id) => {
        this.onOpenAttachment(id).catch(error => {
          console.error(error);
        });
      });

    // Subscribe to the createItem event to handle creating a new attachment
    const createItemSub: Subscription = this.connectAttachmentsDialogRef.componentInstance.createItem
      .subscribe(() => {
        this.onNewTaskAttachment();
      });
  }

  /**
   * Checks if the current user can edit a comment based on their permissions and the comment's creator.
   *
   * @protected
   * @param {string} commentId - The ID of the comment to check.
   * @returns {boolean} True if the user can edit the comment, false otherwise.
   * @throws {Error} If no project user is found.
   */
  protected canEditComment$(commentId: string): Observable<boolean> {
    if (this._permissionService.hasRights('project_update_task_comments')) return of(true);
    return this._projectService.projectUser$.pipe(
      switchMap((projectUser: ProjectUser | null) => {
        if (!projectUser?.id) return of(false);
        return of(this.getCommentCreatorId(commentId) === projectUser.id
          && this._permissionService.hasRights('project_update_task_comments_own'));
      }),
    );
  }

  /**
   * Checks if the current user can delete a comment based on their permissions and the comment's creator.
   *
   * @protected
   * @param {string} commentId - The ID of the comment to check.
   * @returns {Observable<boolean>} An observable emitting true if the user can delete the comment, false otherwise.
   */
  protected canDeleteComment$(commentId: string): Observable<boolean> {
    if (this._permissionService.hasRights('project_delete_task_comments')) return of(true);
    return this._projectService.projectUser$.pipe(
      switchMap((projectUser: ProjectUser | null) => {
        if (!projectUser?.id) return of(false);
        return of(this.getCommentCreatorId(commentId) === projectUser.id
          && this._permissionService.hasRights('project_delete_task_comments_own'));
      }),
    );
  }

  /**
   * Checks if the current user can connect an attachment based on their permissions and the task's author.
   *
   * @protected
   * @returns {Observable<boolean>} An observable emitting true if the user can connect an attachment, false otherwise.
   */
  protected get canConnectAttachment$(): Observable<boolean> {
    if (this._permissionService.hasRights('project_view_connect_task_attachments')) return of(true);
    if (this._auth.currentUser?.uid === (this.currentTask as FirebaseDocumentData<TaskData>)?.author
      && this._permissionService.hasRights('project_view_connect_task_attachment_own_tasks')) return of(true);
    return this._projectService.projectUser$.pipe(
      switchMap((projectUser: ProjectUser | null) => {
        if (!projectUser?.id) return of(false);
        return of(this.currentTask?.creatorId === projectUser.id
          && this._permissionService.hasRights('project_view_connect_task_attachment_own_tasks'));
      }),
    );
  }

  /**
   * Opens the dialog to upload a new document and handles the upload process.
   */
  private onNewTaskAttachment() {
    const dialogRef
      = this._dialog.open<UploadDocumentDialogComponent, UploadDocumentDialogConfig, DocumentData>(UploadDocumentDialogComponent, {
        width: '600px',
        maxWidth: '70vw',
        data: null,
      });

    const afterClosedSub: Subscription = dialogRef.afterClosed().pipe(
      switchMap((document: DocumentData | undefined) => {
        if (!document) {
          afterClosedSub.unsubscribe();
          return EMPTY;
        }
        return this._storageService.uploadProjectDocument(this._projectService.project$.value!.id, document);
      }),
      catchError(error => {
        this._snackBarService.showErrorMessage('Beim Hochladen der Datei ist ein Fehler aufgetreten');
        afterClosedSub.unsubscribe();
        console.error(error);
        return EMPTY;
      }),
    ).subscribe(() => {
      afterClosedSub.unsubscribe();
    });
  }

  /**
   * Opens the attachment with the given ID in a new browser tab.
   * @param id - The ID of the attachment to open.
   */
  private async onOpenAttachment(id: string) {
    const project: Project | null = this._projectService.project$.value;
    if (!project) {
      return handleBasicError('Error while retrieving current project');
    }
    const downloadUrl: string = await lastValueFrom(this._storageService.getProjectDocumentDownloadUrl(project.id, id));
    window.open(downloadUrl, '_blank');
  }

  public getAllFormGroupControlsKeys(controlsObject: { [key: string]: AbstractControl }) {
    return Object.keys(controlsObject);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _getControlName(control: AbstractControl | any): string | null {
    if (!control.parent) {
      throw new Error('Control has no parent');
    }
    const formGroup = control.parent.controls;
    return Object.keys(formGroup).find((name: string) => control === formGroup[name]) || null;
  }

  private _createTaskForm(_taskData?: TaskData | null): FormGroup {
    const canEditTask = this._permissionService.hasRights('project_update_tasks')
      || this._permissionService.hasRights('project_update_tasks_own')
      && (_taskData?.creatorId === this._projectService.projectUser$.value!.id || !_taskData?.id);

    return this._formBuilder.group({
      id: [_taskData?.id || crypto.randomUUID()], // Attention here is a new id created if it is not an existing task
      taskNumber: [_taskData?.taskNumber || null],
      status: [{
        value: _taskData?.status || 'OPEN',
        disabled: !canEditTask
      }],
      title: [{
        value: _taskData?.title,
        disabled: !canEditTask
      }, [Validators.required]],
      type: [{
        value: _taskData?.type || TaskTypeEnum.TASK,
        disabled: !canEditTask
      }, [Validators.required]],
      discipline: [{
        value: _taskData?.discipline || null,
        disabled: !canEditTask
      }],
      date: [_taskData?.date ? new Date(_taskData?.date) : new Date(), [Validators.required]],
      responsibleId: [{
        value: _taskData?.responsibleId || null,
        disabled: !canEditTask
      }],
      creatorId: [
        {
          value: _taskData?.creatorId || this._projectService.projectUser$.value!.id,
          disabled: true,
        },
        [Validators.required],
      ],
      priority: [{
        value: _taskData?.priority || 'MEDIUM',
        disabled: !canEditTask
      }, [Validators.required]],
      description: [{
        value: _taskData?.description || null,
        disabled: !canEditTask
      }],
      newCommentControl: [{
        value: null,
        disabled: !_taskData?.id || !this._permissionService.hasRights('project_create_task_comment'),
      }], // No comment possible if it is a new task
      leistungsPhasen: [{
        value: _taskData?.leistungsPhasen || [],
        disabled: !canEditTask
      }], // No comment possible if it is a new task
    });
  }

  /**
   * Initializes the attachments for the task.
   *
   * This method filters the provided documents to find those associated with the given task
   * and updates the task attachments. It also updates the project attachments to mark those
   * that are connected to the task. If the attachment dialog reference is available, it sets
   * the items in the dialog component.
   *
   * @param {DocumentData[]} documents - The list of documents to initialize.
   * @param {TaskData | null} task - The task for which to initialize the attachments.
   */
  private initializeAttachments(documents: DocumentData[], task: TaskData | null) {
    // If documents and task are provided, filter the documents to find those associated with the task
    if (documents?.length && task) {
      this.taskAttachmentsFromServer = documents.filter(doc => {
        return doc.tasksIds?.some(id => id === task.id);
      });
      this.taskAttachments = [...this.taskAttachmentsFromServer];
    }

    // Map the project attachments and mark those that are connected to the task
    this.projectAttachments = documents.map(doc => {
      if (this.taskAttachments.some(o => o.id === doc.id)) {
        return { ...doc, connected: true };
      }
      return doc;
    });

    // If the attachment dialog reference is available, set the items in the dialog component
    if (!this.connectAttachmentsDialogRef) {
      return;
    }
    this.connectAttachmentsDialogRef.componentInstance?.setItems<DocumentDataX>(this.projectAttachments);
  }

  private loadTaskDataAndDependencies() {
    if (!this.data.id || !this._auth.currentUser?.uid) return; // if task no id or no current user is present, skip loading task data

    this.isLoading$.next(true);
    this._projectService.project$
      .pipe(
        switchMap((project) => {
          if (!project) return combineLatest([of(null), []]);
          const tasks$: Observable<null | TaskData> = this._taskService.getProjectTask(project.id, this.data.id!)
          return combineLatest([
            tasks$,
            this._documentService.getLiveProjectUserDocumentsFromFirestore(
              project.id,
              this._auth.currentUser!.uid
            ),
          ]);
        }),
        catchError(error => {
          this._snackBarService.showErrorMessage('Fehler beim Abrufen der Projekt -Benutzer oder -Aufgaben');
          this.isLoading$.next(false);
          throw new Error(error);
        }),
        takeUntil(this.stop$),
      )
      .subscribe(
        ([task, documents]) => {
          try {
            this.taskForm = this._createTaskForm(task);
            this.currentTask = task;
            this.initializeAttachments(documents, task);
            this.isLoading$.next(false);
          } catch (error) {
            this.isLoading$.next(false);
            this._snackBarService.showErrorMessage('Beim Abrufen der Aufgaben ist ein Fehler aufgetreten');
            console.error(error);
          }
        },
      );

    this._projectService.project$
      .pipe(
        tap(() => this.isLoading$.next(true)),
        switchMap((project: Project | null) => {
          if (!project) return of([]);
          return this._taskService.getLiveAllTaskComments(project.id, this.data.id!);
        }),
        catchError(error => {
          this._snackBarService.showErrorMessage('Fehler beim Abrufen der Projekt -Benutzer oder -Aufgaben');
          this.isLoading$.next(false);
          throw new Error(error);
        }),
        takeUntil(this.stop$),
      )
      .subscribe(comments => {
        this._createCommentsForm(comments || []);
        this.isLoading$.next(false);
      });
  }

  /**
   * Loads the users for the project.
   */
  private _loadUsers() {
    this.isLoading$.next(true);
    if (!this._projectService.project$.value) {
      this._snackBarService.showErrorMessage('Fehler beim Laden des Projektes');
      throw new Error('No project loaded');
    }
    this._projectService.project$
      .pipe(
        switchMap(project => this._projectService.getLiveProjectUsers(project!.id)),
        switchMap((users: ProjectUser[]) => {
          return this._userService.mapAuthUsersDataToProjectUsers(users);
        }),
        takeUntil(this.stop$),
      )
      .subscribe((users: ProjectUserDisplay[]) => {
        this.isLoading$.next(false);
        this.creatorArray = users;
        this.responsibleArray = [{ id: null, firstName: '-' } as unknown as ProjectUserDisplay, ...users];
      });
  }
}
