import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../user.service';
import { environment } from './../../../../environments/environment';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Parameters<T extends (...args: any) => any> = T extends (...args: infer P) => any ? P : never;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let gtag: (type: string, eventName: string, eventParams: { [key: string]: any }) => any;

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(private _userService: UserService, private router: Router) {}

  public initializeAnalytics() {
    if (!this._isAnalyticsEnabled()) return;
    try {
      gtag('config', environment.firebaseConfig.measurementId, {});
    } catch (error) {
      console.error('ERROR initializing analytics', error);
    }
  }

  public sendPageView(url: string) {
    if (!this._isAnalyticsEnabled()) return;
    try {
      gtag('config', environment.firebaseConfig.measurementId, {
        page_path: url,
      });
    } catch (error) {
      console.error('ERROR', error);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public sendEvent(eventName: string, eventParams: { [key: string]: any }) {
    if (!this._isAnalyticsEnabled()) return;
    try {
      gtag('event', eventName, eventParams);
    } catch (error) {
      console.error('ERROR', error);
    }
  }

  private _isAnalyticsEnabled(): boolean {
    return !!this._userService.euleUser$.value?.analytics?.['GA_baselineReports'];
  }
}
