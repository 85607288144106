@if (data.dynamicTitle) {
<h2 mat-dialog-title>{{ data.dynamicTitle }}</h2>
} @else {
<h2 mat-dialog-title>Sind Sie sicher?</h2>
} @if (data.dynamicContent) {
<mat-dialog-content>
  <div [innerHTML]="data.dynamicContent"></div>
</mat-dialog-content>
}
<mat-dialog-actions>
  <button
    mat-stroked-button
    [mat-dialog-close]="false"
    aria-label="cancel"
    [attr.data-cy]="CY_CONFIRMDIALOG_BUTTON_CANCEL"
    (click)="onClick(CY_CONFIRMDIALOG_BUTTON_CANCEL, false)"
  >
    Abbrechen
  </button>
  <button
    mat-flat-button
    color="primary"
    [mat-dialog-close]="true"
    aria-label="ok"
    [attr.data-cy]="CY_CONFIRMDIALOG_BUTTON_OK"
    (click)="onClick(CY_CONFIRMDIALOG_BUTTON_OK, true)"
  >
    Ok
  </button>
</mat-dialog-actions>
