import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { AnalyticsService } from '../../services/analytics/analytics.service';

export const CY_CONFIRMDIALOG_BUTTON_CANCEL = 'confirm_dialog.button.cancel';
export const CY_CONFIRMDIALOG_BUTTON_OK = 'confirm_dialog.button.ok';

export type ConfirmDialogData = {
  /**
   * For localization
   * TODO: remove or add localization
   */
  titleLangKey?: string;

  /**
   * Dynamic title
   */
  dynamicTitle?: string;

  /**
   * For localization
   * TODO: remove or add localization
   * content key that will be translated from i18n file
   */
  contentLangKey?: string;

  /**
   * Dynamic content
   */
  dynamicContent?: string;
};

@Component({
  selector: 'eule-confirm-dialog',
  standalone: true,
  imports: [MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose, MatButtonModule],
  templateUrl: './confirm-dialog.component.html',
  styleUrl: './confirm-dialog.component.scss',
})
export class ConfirmDialogComponent {
  CY_CONFIRMDIALOG_BUTTON_CANCEL = CY_CONFIRMDIALOG_BUTTON_CANCEL;
  CY_CONFIRMDIALOG_BUTTON_OK = CY_CONFIRMDIALOG_BUTTON_OK;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData, private _analyticsService: AnalyticsService) {}

  onClick(label: string, value: boolean) {
    this._analyticsService.sendEvent('click', {
      category: 'button',
      label,
      value,
    });
  }
}
